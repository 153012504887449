import React from "react";
import './input.css';
const Input  = (props) =>{
    return (
        <div className={props.padding ? 'input-row': ''}>
            <input className={props.type === 'checkbox' ? 'check-box': ''} placeholder={props.placeholder} type={props.type} {...props.input}/>
            {props.icon ? props.icon: ''}
            {props.submitted && props.meta.error && <span className="error">{props.meta.error}</span>}
        </div>
    );
}
export default Input;