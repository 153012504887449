import React, { useState, useEffect } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { useSelector } from 'react-redux';
import Input from '../Input';
import Select from '../Select';
import './signup.css';
import { config } from '../../enviroment';
import {
	requiredValidation,
	emailValidation,
	passwordValidation,
	validateForm,
} from '../Validation/index';
import { ajax } from 'rxjs/ajax';
import moment from 'moment';
import 'moment-timezone';
// import Amplitude from 'amplitude-js';
// import {amplitudeKey} from '../../env.js'

const relationOptions = [
	{ value: '', label: 'select relation' },
	{ value: 'Mother', label: 'Mother' },
	{ value: 'Father', label: 'Father' },
	{ value: 'Grandfather', label: 'Grandfather' },
	{ value: 'Grandmother', label: 'Grandmother' },
	{ value: 'Caregiver', label: 'Caregiver' },
	{ value: 'Teacher', label: 'Teacher' },
	{ value: 'Other', label: 'Other' },
];

const Signup = (props) => {
	const signUpForm = useSelector((state) => state.form);
	const [languageOptions, setLanguageOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [msg, setMsg] = useState('');
	const [viewPassword, setPasswordType] = useState(false);
	const { submitting } = props;
	const amplitudeData = {
		first_name: '',
		last_name: '',
		email: '',
		code: '',
	};

	useEffect(() => {
		ajax('https://api.aboundparenting.com/resources/api/language/').subscribe({
			next: (value) =>
				value.response.map((item) => {
					setLanguageOptions((prevValue) => [
						{ value: item.id, label: item.language },
						...prevValue,
					]);
				}),
			error: (err) => console.log(err),
		});
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		setSubmitted(true);
		if (validateForm(signUpForm.signup.values)) {
			setMsg('');
			setLoading(true);
			let timeZone = moment.tz.guess();
			let utc = moment().format('hh:mm a');

			ajax
				.post(
					config.production,
					{
						...signUpForm.signup.values,
						timezone: timeZone,
						utc_time: utc,
					},
					{ 'Content-Type': 'application/json' }
				)
				.subscribe(
					(res) => {
						amplitudeData.first_name = signUpForm.signup.values.first_name;
						amplitudeData.last_name = signUpForm.signup.values.last_name;
						amplitudeData.email = signUpForm.signup.values.email;
						amplitudeData.code = signUpForm.signup.values.code;
						// Amplitude.init(amplitudeKey,res.response.data.id);
						// Amplitude.logEvent('Signup', amplitudeData);
						setLoading(false);
						props.history.push('/success');
					},
					(err) => {
						setLoading(false);
						if (
							err.response &&
							err.response.error &&
							err.response.error === 'Invalid Coupon Code'
						) {
							setMsg('Invalid Code - all codes are case-sensitive.');
						} else if (err.response && err.response.error) {
							setMsg(err.response.error);
						} else {
							setMsg('Unable to create account. Internal server error.');
						}
					}
				);
		}
	};

	const togglePassword = () => {
		setPasswordType(!viewPassword);
	};
	return (
		<React.Fragment>
			<div className='display-flex-justify-space-around m-5'>
				<img
					className='display-flex-justify-space-around'
					src={require('../../assets/images/abound-logo.png')}
					alt=''
				/>
			</div>
			<p className='text-center primary-color paragraph-margin'>
				Create a free account through our
			</p>
			<p className='text-center primary-color paragraph-margin'>
				partnership with your child's school.
			</p>
			<p className='text-center primary-color paragraph-margin small'>
				<i>Abound supports parents of children ages 3 yrs through grade 3.</i>
			</p>
			<div className='display-flex-justify-space-around m-5'>
				<Form onSubmit={handleSubmit}>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation]}
						placeholder='first name'
						name='first_name'
						component={Input}
						type='text'
					/>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation]}
						placeholder='last name'
						name='last_name'
						component={Input}
						type='text'
					/>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation, emailValidation]}
						placeholder='email address'
						name='email'
						component={Input}
						type='email'
					/>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation, passwordValidation]}
						placeholder='password'
						name='password'
						component={Input}
						type={viewPassword ? 'text' : 'password'}
						icon={
							<span
								onClick={() => togglePassword()}
								className={
									viewPassword
										? 'fa fa-fw fa-eye field-icon fa-eye-slash'
										: 'fa fa-fw fa-eye field-icon'
								}
							></span>
						}
					/>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation]}
						placeholder='relation'
						name='relation'
						component={Select}
						type='select'
						options={relationOptions}
					/>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation]}
						placeholder='language'
						name='language'
						component={Select}
						type='select'
						options={languageOptions}
					/>
					<Field
						padding={true}
						submitted={submitted}
						validate={[requiredValidation]}
						placeholder='code'
						name='code'
						component={Input}
						type='text'
					/>
					<div className='conditions'>
						<Field
							submitted={submitted}
							name='terms'
							id='terms'
							component={Input}
							type='checkbox'
							padding={false}
							validate={[requiredValidation]}
						/>
						<a
							className='terms-text'
							href='https://aboundparenting.com/terms-of-service'
							target='_blank'
						>
							Click the above box to agree to Abound's Terms and Conditions.
						</a>
					</div>
					<button className='creat-btn' type={'submit'} disabled={submitting}>
						Create Account
						{loading ? <div className='lds-dual-ring'></div> : ''}
					</button>
					<p className='msg'>{msg}</p>
				</Form>
			</div>
		</React.Fragment>
	);
};

export default reduxForm({
	form: 'signup',
	initialValues: {
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		code: '',
		relation: '',
		terms: false,
	},
})(Signup);
