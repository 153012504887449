import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Signup from '../components/Signup';
import Success from '../components/Success';

export default function Routes() {
    return (
        <Router>
                <Switch>
                    <Route exact path="/" component={Signup} />
                    <Route exact path="/success" component={Success} />
                </Switch>
        </Router>
    );
}