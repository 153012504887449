import React from 'react';
import { config } from '../../enviroment';
import './success.css';

const Success = (props) => {
	return (
		<React.Fragment>
			<div className='display-flex-justify-space-around m-5'>
				<img
					className='display-flex-justify-space-around'
					src={require('../../assets/images/abound-logo.png')}
					alt=''
				/>
			</div>
			<p className='text-center primary-color paragraph-margin'>Success!</p>
			<p className='text-center primary-color paragraph-margin'>
				Next, download <b>Abound Parenting</b> from
			</p>
			<p className='text-center primary-color paragraph-margin'>
				the app store on your phone, and simply login.
			</p>
			<div className='display-flex-justify-space-around m-5'>
				<a href='https://aboundparenting.com/product/'>
					<button className='learn-more-btn'>Learn More</button>
				</a>
			</div>
			<div className='display-flex-justify-space-around m-5'>
				<a href={config.appUrl}>
					<img
						className='app-store'
						src={require('../../assets/images/ios.png')}
					/>
				</a>
			</div>
			<div className='display-flex-justify-space-around m-5'>
				<a href={config.playStoreUrl}>
					<img
						className='app-store '
						src={require('../../assets/images/android.png')}
					/>
				</a>
			</div>
			{/*       <p className='text-center primary-color paragraph-margin'>Coming to Android</p> */}
			{/*       <p className='text-center primary-color paragraph-margin'>in spring 2020</p> */}
		</React.Fragment>
	);
};

export default Success;
