export const requiredValidation =  (value, all, props, name) => (value || typeof value === 'number' || name === "language" ? undefined : `The ${name.split('_').join(' ')} field is required`);

export const emailValidation = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const passwordValidation = value =>
  value && value.length < 6 ? `Must be ${6} characters or more` : undefined

export const validateForm = (form) => {
	let valid = true;
	if (!form.language) {
		form.language = '2';
	}
	if (
		!form.first_name ||
		!form.last_name ||
		!form.email ||
		!form.password ||
		!form.code ||
		!form.terms ||
		!form.relation
	) {
		valid = false;
	}
	return valid;
}



