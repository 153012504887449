import React from 'react';
import './select.css';
const Select = (props) => {
	return (
		<div className={props.padding ? 'select-row' : ''}>
			<select
				className={props.type === 'select' ? 'select' : ''}
				placeholder={props.placeholder}
				type={props.type}
				{...props.input}
			>
				{props.options?.map((option) => (
					<option value={option.value} key={option.value}>
						{option.label}
					</option>
				))}
			</select>
			{props.icon ? props.icon : ''}
			{props.submitted && props.meta.error && (
				<span className='error'>{props.meta.error}</span>
			)}
		</div>
	);
};
export default Select;
